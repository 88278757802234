/* jshint laxcomma:true */
/* 
 *  © Chris How, Primesolid 2016
 *  All rights reserved.
 */

require('imagesloaded');
require('flexslider');
require('jquery.easing');
import './plugins.js';
import enableInlineVideo from 'iphone-inline-video';


var $BODY;
var $HTML;
var $WINDOW;
var newsManager;
var contactManager;
var $BREAKPOINTMANAGER;



var $ = jQuery;

$(function () {
	var slideshow;

	$BODY = $('body');
	$HTML = $('html');
	$WINDOW = $(window);
	$BREAKPOINTMANAGER = new BreakpointManager();

	// touch class to html on touch devices. 
	if ("ontouchstart" in window || "ontouch" in window) { $HTML.addClass('touch'); }


	// Enable video on iOS
	$('video').each(function () {
		enableInlineVideo(this);
	});

	// Make links open in new window
	// $('.news-items a').each(function() {
	//    var a = new RegExp('/' + window.location.host + '/');
	//    if(!a.test(this.href)) {
	//        $(this).click(function(event) {
	//            event.preventDefault();
	//            event.stopPropagation();
	//            window.open(this.href, '_blank');
	//        });
	//    }
	// });


	if ($BODY.hasClass('single-work')) {
		new WorkSlideshow('work', '.slideshow');
	} else if ($BODY.hasClass('home')) {

		// if Splash screen is showing
		if ($('#splash').length) {
			// When the Tej font has loaded
			fontSpy('tej-font', {
				glyphs: 'TEJ',
				success: function () {
					// Start splash screen
					$('#splash').imagesLoaded().done(function () {
						// console.log('imagesLoaded');
						new WorkSlideshow('splash', '.splash-slideshow');
					});
				},
				failure: function () {
					// console.log("Tej icons failed to load");
				}
			});

		} else {
			// Splash is hidden, so start home slideshow
			$('.slideshow').imagesLoaded().done(function () {
				$('#loading-indicator').fadeOut();
				new WorkSlideshow('home', '.slideshow');
				$('.slideshow').flexslider('play');
				setTimeout(function () {
					$BODY.addClass('splash-done');
					$('.home-logo').addClass('animated-in');
				}, 100);
			});
		}


		new WorkSlideshow('home', '.slideshow');
	} else if ($BODY.hasClass('work')) {
		new RolloverManager();
	} else if ($BODY.hasClass('studio') && isIOS()) {
		(function () {
			// get the background image and apply it to the HTML element
			$HTML.css({
				'background-image': $('.background-image').css('background-image')
			}).addClass('studio');
		})();

	} else if ($BODY.hasClass('single-post')) {
		new JournalZoomManager();
	}


	$('header .hamburger, .nav-header .hamburger').on('click', function () {
		if ($BODY.hasClass('nav-showing')) {
			$BODY.removeClass('nav-showing');
		} else {
			$BODY.addClass('nav-showing');
		}

		return false;
	});

});



function JournalZoomManager() {
	var that = this;


	$('.content img').on('mouseover', function () {
		var $img = $(this)
			, maxWidth = parseInt($img.attr('width'), 10)
			;

		// Is this image zoomable? 
		if (!$img.hasClass('no-zoom') && ($img.width() < maxWidth)) {
			// console.log('current width: ' + $img.width() + ', max-width: ' + maxWidth);
			$img.addClass('zoomable');
		}
	}).on('mouseleave', function () {
		$(this).removeClass('zoomable');
	});

	$('.content img.zoomable').live('click', function () {
		var $img = $(this)
			;

		// Skip on phone 
		if ($BREAKPOINTMANAGER.breakpoint === 'phone') {
			return;
		}

		that.zoom($img);
	});

	$('#zoom-lightbox').live('click', function () {

		var $lightbox = $(this);

		$lightbox.fadeOut('fast', function () {
			$lightbox.remove();
		});
	});
}

JournalZoomManager.prototype.zoom = function ($img) {
	var $lightbox = $('<div id="zoom-lightbox" onclick="void(0)"><div class=inner></div></div>')
		, $clone = $img.clone()
		, url = $img.attr('src')
		, maxWidth = parseInt($img.attr('width'), 10)
		, maxHeight = parseInt($img.attr('height'), 10)
		, topMargin = 40
		;

	// $clone.appendTo($lightbox);
	$BODY.append($lightbox);
	$lightbox.find('.inner').css({
		'background-image': 'url(' + url + ')',
		'max-height': maxHeight,
		'max-width': maxWidth
	});
	$lightbox.fadeIn('fast');
	console.log('zoom!');
};


function RolloverManager() {
	var that = this
		, $items = $("*[data-rollover-color]")
		;

	$items.each(function () {
		var $this = $(this);

		$this.parent().hover(function () {
			$this.css('color', $this.data('rollover-color'));
		}, function () {
			$this.css('color', $this.data('original-color'));
		});
	});
}



function WorkSlideshow(which, selector) {
	var that = this
		, config
		;

	this.$slider = $(selector);
	this.$pageNumber = $('.pager span');
	this.$info = $('.info');
	this.$infoLinks = $('.info-links');

	config = this.getConfig(which);

	this.$slider.flexslider(config);

	$WINDOW.on('debouncedresize', function () {
		that.resizeSlides();
	});

	$('video').on('loadedmetadata', function () {
		that.resizeSlides();
	});

	setInterval(function () {
		that.resizeSlides();
	}, 500);


	$('#toggle-info').on('click', function () {

		if ($BODY.hasClass('info-showing')) {
			that.$info.removeClass('scrolls');
			$BODY.removeClass('info-showing');
			that.$info.css('max-height', 0);
			$(this).text('Info');

		} else {
			$BODY.addClass('info-showing');
			that.$info.attr('style', 'max-height: ' + that.idealHeight);
			$(this).text('Close Info');
			setTimeout(function () {

				if (that.$info[0].scrollHeight > that.$info.innerHeight()) {
					that.$info.addClass('scrolls');
				}
			}, 550);
		}

		return false;
	});

	$('#go-back').on('click', function () {
		var referrerArray = document.referrer.split('/');

		if (referrerArray.length >= 4 && referrerArray[3] == 'work') {
			// console.log('going back');
			window.history.back();

			return false;
		}
	});
}


WorkSlideshow.prototype.getConfig = function (which) {
	var that = this
		, callback
		, config
		;

	switch (which) {
		case 'work':
			config = {
				animation: 'slide',
				video: true,
				easing: 'easeInOutExpo',
				slideshow: false,
				start: function () {
					that.$slider.css('opacity', 1);
					that.$slideItems = that.$slider.find('li img, li video');
					$('.slideshow video').each(function () {
						this.play();
					});
					that.resizeSlides();

					$('.left-arrow').on('click', function () {
						$('.flex-prev').trigger('click');

					});

					$('.right-arrow').on('click', function () {
						$('.flex-next').trigger('click');
					});
				},
				after: function (data) {
					that.$pageNumber.text(data.animatingTo + 1);
				}
			};
			break;

		case 'home':
			config = {
				animation: 'slide',
				video: true,
				easing: 'easeInOutExpo',
				slideshow: false,
				slideshowSpeed: 3000,
				start: function () {
					that.$slideItems = that.$slider.find('img, video');
					that.resizeSlides();
				},
				after: function (data) {
					that.$pageNumber.text(data.animatingTo + 1);
				}
			};
			break;


		case 'splash':
			callback = function () {
				$BODY.addClass('splash-done');
				that.$slider.fadeOut('fast', function () {
					that.$slider.remove();
					$('.home-logo').addClass('animated-in');
				});
			};

			config = {
				// animation: 'slide',
				video: true,
				easing: 'easeInOutExpo',
				slideshow: true,
				slideshowSpeed: 500,
				animationSpeed: 50,
				start: function () {
					$('.skip-splash a').on('click', function () {
						callback();

						return false;
					});
					that.$slider.css('opacity', 1);
					that.$slideItems = that.$slider.find('img, video');
					that.resizeSlides();
					$('#loading-indicator').fadeOut();
					$('.slideshow').flexslider('play');
				},
				after: function (data) {
					if (data.animatingTo === 9) {
						setTimeout(function () {
							callback();
						}, 300);
					}
				}

			};
			break;


		default:
			alert('Unknown slideshow type: ' + which);
			break;
	}

	return config;
};

WorkSlideshow.prototype.resizeSlides = function () {
	var that = this
		, i
		, slideshowW = this.$slider.width()
		, slideshowH = this.$slider.height()
		, slideshowAspectRatio = slideshowW / slideshowH
		, itemW
		, itemH
		, newW
		, newH
		, leftAdjust
		, topAdjust
		, itemAspectRatio
		, thisItem
		, $thisItem
		;

	// calculate best max-height for info panel
	(function () {
		var idealHeight
			;

		// Bail out if no info panel
		if (that.$info.length === 0) {
			return;
		}



		idealHeight = $WINDOW.height() - that.$infoLinks.outerHeight(false) - that.$info.position().top;

		that.idealHeight = 'calc(' + idealHeight + 'px - 3.25rem)';

		if ($BODY.hasClass('info-showing')) {
			that.$info.attr('style', 'max-height: ' + that.idealHeight);
		}
	})();

	// console.log('slideshowH: ' + slideshowH);
	// console.log('slideshowW: ' + slideshowW);

	if (!this.$slideItems) {
		return;
	}

	for (i = 0; i < this.$slideItems.length; i++) {
		thisItem = this.$slideItems[i];
		$thisItem = $(thisItem);

		switch (thisItem.tagName) {
			case 'IMG':
				itemW = thisItem.naturalWidth;
				itemH = thisItem.naturalHeight;
				break;

			case 'VIDEO':
				itemW = thisItem.videoWidth;
				itemH = thisItem.videoHeight;
				break;

		}

		// console.log('itemW: ' + itemW + ', itemH: ' + itemH);

		itemAspectRatio = itemW / itemH;

		if (itemAspectRatio < slideshowAspectRatio) {
			// Item is too wide
			newW = slideshowW;
			newH = slideshowW / itemAspectRatio;
			// console.log('Too wide. Adjusted to W:' + newW);

			topAdjust = (newH - slideshowH);
			leftAdjust = 0;

			// console.log('topAdjust: ' + topAdjust);
		} else {
			// Item is too tall
			newH = slideshowH;
			newW = slideshowH * itemAspectRatio;
			// console.log('Too tall. Adjusted to W:' + newW);

			topAdjust = 0;
			leftAdjust = (newW - slideshowW);
			// console.log('leftAdjust: ' + leftAdjust);
		}


		$thisItem.css({
			'width': newW,
			'height': newH,
			'left': -1 * leftAdjust / 2,
			'top': -1 * topAdjust / 2
		});

	}




};


function BreakpointManager() {
	var that = this;
	this.breakpoint = null;

	$WINDOW.on('resize', function () {
		that.refresh();
	});

	this.refresh();

}

BreakpointManager.prototype.refresh = function () {
	var newValue = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
	if (this.breakpoint !== newValue) {
		this.breakpoint = newValue;
		$WINDOW.trigger('breakpointChanged');
	}
};



